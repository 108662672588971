<template>
<DefaultLayout>
    <div class="main-content user-content user-group-add-new">
        <Header back-link="/user-group-detail" header-title="New User Group" header-breadcrumb="User / User Group / New User Group" />
        <div class="table-heading user-detail-edit">
            <h2 class="mb-4">{{ addNewUserGroup.userGroupName }} <a href="#" data-toggle="modal" data-target="#editNewUserGroupModal"><i class="fa fa-pencil-alt"></i></a></h2>
            <div class="d-flex justify-content-between">
                <div class="description">
                    <p class="mr-4">Description :</p>
                    <p><input v-model="addNewUserGroup.description" type="text" class="form-control"></p>
                </div>
            </div>
        </div>
        <div class="content-wrapper">
            <div class="text-right mt-5">
                <button data-toggle="modal" data-target="#addProjectModal" class="btn btn-light btn-add-modal"><i class="fa fa-plus"></i></button>
            </div>
            <div class="table-responsive mt-3">
                <table class="iot-table table table-hover" id="userGroupDetailTable">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Sensor ID <i class="fa fa-sort"></i></th>
                            <th>Display Name</th>
                            <th>Profile</th>
                            <th>Type</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in allDevicesAndUsers" :key="index">
                            <td>
                                <span class="table-icon-circle">
                                    <i class="fa fa-satellite-dish"></i>
                                </span>
                            </td>
                            <td>
                                <router-link to="/device-detail">{{ item.id }}</router-link>
                            </td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.profileName }}</td>
                            <td>{{ item.type}}</td>
                            <td>
                                <button @click="removeItem(item)" class="action-link"><img src="../../../assets/img/icons/icon-close.svg" alt="Icon"></button>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <div class="action-btn text-right mt-5">
                <a href="#" @click="saveUserGroupAllData()" class="btn btn-dark mr-3">Save</a>
                <a href="#" @click="discard" class="btn btn-dark">Discard</a>
            </div>
        </div>
    </div>
</DefaultLayout>

<!-- Modal -->
<div class="modal fade iot-modal add-project-modal" id="addProjectModal" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header pt-0 pb-0">
                <h5 class="modal-title" id="exampleModalLongTitle">
                    <div class="input-group">
                        <div class="input-group-prepend search-icon">
                            <span class="input-group-text"><img src="../../../assets/img/icons/search-icon.svg" alt="ICON"></span>
                        </div>
                        <input v-model="searchData" type="text" class="form-control" placeholder="search" aria-label="Username" aria-describedby="basic-addon1">
                        <button @click="clearSearch()" class="clear-search">
                            <img src="../../../assets/img/icons/icon-close-white.svg" alt="ICON">
                        </button>
                    </div>
                </h5>

            </div>
            <div class="modal-body">
                <ul class="nav nav-tabs" id="projectTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="all-tab" data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="true">
                            All

                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="devices-tab" data-toggle="tab" href="#devices" role="tab" aria-controls="devices" aria-selected="false">
                            <i class="fas fa-desktop"></i>
                            Devices
                            <span class="badge badge-light">{{devices.length}}</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="users-tab" data-toggle="tab" href="#users" role="tab" aria-controls="users" aria-selected="false">
                            <img src="../../../assets/img/icons/user-icon.svg" alt="Icon">
                            User
                            <span class="badge badge-light">{{users.length}}</span>
                        </a>
                    </li>
                </ul>
                <div class="tab-content" id="projectTabContent">
                    <div class="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                        <ul class="list-group list-group-flush">
                            <li v-for="(item,index) in all" :key="index" class="list-group-item">
                                <h6>{{ item.name}}</h6>
                                <span>{{item.profileName}}</span>
                                <button @click="addItem(item)" class="btn btn-dark btn-add"><i class="fa fa-plus"></i></button>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-pane fade" id="devices" role="tabpanel" aria-labelledby="devices-tab">
                        <ul class="list-group list-group-flush">
                            <li v-for="(device,index) in devices" :key="index" class="list-group-item">
                                <h6>{{device.display_name}}</h6>
                                <span>{{device.profile_name}}</span>
                                <button @click="addSensorToGroup(device.id)" class="btn btn-dark btn-add"><i class="fa fa-plus"></i></button>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-pane fade" id="users" role="tabpanel" aria-labelledby="users-tab">
                        <ul class="list-group list-group-flush">
                            <li v-for="(user,index) in users" :key="index" class="list-group-item">
                                <h6>{{ user.name}}</h6>
                                <span>{{user.profileName}}</span>
                                <button @click="addItem(user)" class="btn btn-dark btn-add"><i class="fa fa-plus"></i></button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade iot-modal edit-new-user-modal" id="editNewUserGroupModal" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    Edit User Group
                </h5>
            </div>
            <div class="modal-body">
                <form>
                    <div class="form-group">
                        <label for="userGroup" class="col-form-label"><b>User Group:</b></label>
                        <input v-model="addNewUserGroup.userGroupName" type="text" class="form-control" id="userGroup">
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button @click="saveUserGroup()" type="button" class="btn btn-dark" data-dismiss="modal">Save</button>
                <button @click="discardUserGroupNameChange()" type="button" class="btn btn-dark" data-dismiss="modal">Discard</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import DefaultLayout from '@/components/DefaultLayout.vue'
import Header from '@/components/Header.vue'
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import axios from 'axios'
export default {
    name: 'UserGroupAddNew',
    data: function () {
        return {
            addNewUserGroup: {
                userGroupName: 'User Group',
                description: '',
            },
            devices: [],
            users: [{
                    id: 'sigfox-4F45F3U1',
                    name: "Sigfox tilt user A",
                    profileName: "SPEC_CODE_SIGFOX_TILT",
                    type: "TILT",
                    identity: "user",
                },
                {
                    id: 'sigfox-4F45F3U2',
                    name: "Sigfox tilt user B",
                    profileName: "SPEC_CODE_SIGFOX_TILT",
                    type: "TILT",
                    identity: "user",
                },
            ],
            all: [],
            allDevicesAndUsers: [],
            searchData: '',
            newGroupID: '',
            addedDevices: [],
            addedUsers: [],
            sensorsOfGroup:[]
        }
    },
    components: {
        DefaultLayout,
        Header
    },
    created() {
        this.allItems();
        this.userGroupNameBeforeEdit = this.addNewUserGroup.userGroupName
    },
    methods: {
        discard() {
            this.$router.push("/user-group");
        },
        discardUserGroupNameChange() {
            this.addNewUserGroup.userGroupName = this.userGroupNameBeforeEdit
        },
        removeItem(item) {
            this.allDevicesAndUsers.splice(this.allDevicesAndUsers.indexOf(item), 1);
        },
        clearSearch() {
            this.searchData = "";
        },
        saveUserGroup() {
            if (this.addNewUserGroup.userGroupName == '' || this.addNewUserGroup.description == '') {
                createToast('User group name/description can not be empty', {
                position: 'bottom-right',
                showIcon: 'true',
                type: 'danger',
                transition: 'zoom',
            });
                return;
            }
            axios.post("groups/", {
                name: this.addNewUserGroup.userGroupName,
                description: this.addNewUserGroup.description
            }).then((response) => {
                
                this.newGroupID = response.data.data.id;
                createToast('User group added successfully', {
                position: 'bottom-right',
                showIcon: 'true',
                type: 'success',
                transition: 'zoom',
            });
            });
        },
        addSensorToGroup(sensor_id) {
            if (this.newGroupID != '') {
                axios.post("sensors/" + sensor_id + "/groups?group_id=" + this.newGroupID).then((response) => {
                    if (response.data.status == 'SUCCESS')
                    {
                        this.getGroupSensers();
                        createToast('Sensor added to the user group', {
                            position: 'bottom-right',
                            showIcon: 'true',
                            type: 'danger',
                            transition: 'zoom',
                        });
                    }
                });
            } else createToast('Add user group first', {
                position: 'bottom-right',
                showIcon: 'true',
                type: 'danger',
                transition: 'zoom',
            });
        },
        getGroupSensers()
        {
            if(tihs.newGroupID!='')
            {
            axios.get("sensors/?group_id=" + this.groupId).then((response) => {
                this.sensorsOfGroup = response.data.data;
            })
        }
        },
        addItem(item) {

            let allDevicesAndUserFound = this.allDevicesAndUsers.map((loopVariable) => loopVariable.id).indexOf(item.id);
            if (allDevicesAndUserFound == -1)
                this.allDevicesAndUsers.push(item);
            if (item.identity == 'user') {
                let found = this.addedUsers.map((loopVariable) => loopVariable.id).indexOf(item.id);
                if (found == -1)
                    this.addedUsers.push(item.id);

            } else {
                let found = this.addedDevices.map((loopVariable) => loopVariable.id).indexOf(item.id);
                if (found == -1)

                    this.addedDevices.push(item.id);

            }

            console.log(this.addedDevices)
        },
        allItems() {
            this.all = [...this.devices, ...this.users];
        },
        saveUserGroupAllData() {
            //                let user_ids = [];
            //                this.addedUsers.map( (item) => {
            //                    user_ids.push(item)
            //                    }
            //                );
            //                console.log(user_ids);return;
            axios.post("groups/" + 2 + "/users", {
                user_ids: [2]
                //                    user_ids: this.addedUsers
            }).then((response) => {
                console.log(response);
            }).catch((errors) => {
                console.log(errors)
            });
            axios.post("groups/" + 2 + "/sensors", {
                sensor_ids: [2]
                //                    sensor_ids: this.addedDevices
            }).then((response) => {
                console.log(response);
            }).catch((errors) => {
                console.log(errors)
            });
        },

        getAllSensors() {
            axios.get("sensors/info").then((response) => {
                this.devices = response.data.data;
            });
        },

        // api is not ready
        // getAllUsers() {
        //     axios.get("users").then((response) => {
        //         this.devices = response.data.data;
        //     });
        // },

    },
    mounted() {

        this.getAllSensors();
        // this.getAllUsers();

    },
}
</script>
