<template>
    <DefaultLayout>
        <Header back-link="/devices" header-title="Device Detail"
                header-breadcrumb="Sensor / Devices / Device Detail"/>
        <div class="main-content device-detail">

            <h2 class="device-detail-title">Water Level A</h2>
            <div class="row justify-content-center">
                <div v-if="loaderActive" class="main-card">
                    <loader :active="loaderActive" />
                  </div>
                <div v-else class="col-md-10">
                    <div class="card device-detail-card">
                        <ul>
                            <li><p><b>Sensor ID :</b></p>
                                <p>{{deviceDetails.sensor_id}}</p></li>
                            <li><p><b>Display Name :</b></p>
                                <p>{{ deviceDetails.display_name }}</p></li>
                            <li><p><b>Sensor Profile :</b></p>
                                <p>{{ deviceDetails.profile_name }}</p></li>
                            <li><p><b>Type :</b></p>
                                <p>{{ deviceDetails.sensor_type }}</p></li>
                        </ul>
                        <ul>
                            <li><p><b>Group name :</b></p>
                                <p>{{ deviceDetail.groupName }}</p></li>
                            <li><p><b>Description :</b></p>
                                <p>{{ deviceDetail.description }}</p></li>
                        </ul>
                        <router-link class="link" :to="'/devices-detail-config/'+deviceDetails.id"><i class="fa fa-angle-right"></i></router-link>
                    </div>
                    <div class="reading-summary d-flex justify-content-between align-items-center mt-5">
                        <h4>Readings Summary</h4>
                        <div class="d-flex align-items-center justify-content-between">
                            <Datepicker class="mr-3" v-model="startDate"></Datepicker>
                            <span class="mr-3">TO</span>
                            <Datepicker v-model="endDate"></Datepicker>
                        </div>
                    </div>
                    <div class="devices-chart-modal mt-5" style="height: 400px">
                        <div class="row">
                            <router-link :to="'/devices-detail-table-view/'+sensorID">
                                <div class="col-md-12">
                                    <div v-if="chartType == 'Last Handler'" class="cross-line last-handler"></div>
                                    <div v-else-if="chartType == 'Signal Strength'"
                                         class="cross-line signal-strength"></div>
                                    <div v-else-if="chartType == 'Level'" class="cross-line level"></div>
                                    <div v-else class="cross-line"></div>
                                    <div class="position-relative">
                                        <DevicesChart :chData="chData"></DevicesChart>
                                        <!--<div v-if="isLoading" class="loader">-->
                                            <!--<div class="spinner-border text-secondary rotation-animation" role="status">-->
                                                <!--<i class="fa fa-sync-alt"></i>-->
                                            <!--</div>-->
                                        <!--</div>-->
                                        <div class="itl-loader" :class="[isLoading ? 'active':'']"><i class="fa fa-spin fa-spinner"></i></div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div class="chart-links">
                            <button :disabled="isLoading" @click.prevent="setChartData('Last Handler')"><span
                                class="yellow"></span>Last Handler
                            </button>
                            <button :disabled="isLoading" @click.prevent="setChartData('Signal Strength')"><span
                                class="blue"></span>Signal Strength
                            </button>
                            <button :disabled="isLoading" @click.prevent="setChartData('Level')"><span
                                class="orange"></span>Level
                            </button>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </DefaultLayout>
</template>

<script>
    import DefaultLayout from '@/components/DefaultLayout.vue'
    import Header from '@/components/Header.vue'
    import Loader from '@/components/Loader.vue'
    import DevicesChart from '@/components/DevicesChart.vue'
    import Datepicker from '@vuepic/vue-datepicker'
    import '@vuepic/vue-datepicker/dist/main.css'
    //
    //import Chart from 'chart.js/auto';
    const dataAll = {
        datasets: [
            {
                label: ['Last Handler'],
                data: [{
                    x: 4,
                    y: 7,
                    r: 5
                }, {
                    x: 3,
                    y: 4,
                    r: 5
                }, {
                    x: 7,
                    y: 5,
                    r: 5
                },],
                backgroundColor: [
                    '#FBD379',
                ]
            },
            {
                label: ['Signal Strength'],
                data: [{
                    x: 12,
                    y: 10,
                    r: 5
                }, {
                    x: 14,
                    y: 11,
                    r: 5
                }, {
                    x: 17,
                    y: 8,
                    r: 5
                },],
                backgroundColor: '#4D80DD',
            },
            {
                label: ['Level'],
                data: [{
                    x: 12,
                    y: 10,
                    r: 5
                }, {
                    x: 11,
                    y: 16,
                    r: 5
                }, {
                    x: 13,
                    y: 8,
                    r: 5
                },],
                backgroundColor: '#EE6C6C',
            }

        ],
    };
    const dataLastHandler = {
        datasets: [
            {
                label: ['Last Handler'],
                data: [
                    {
                        x: 4,
                        y: 7,
                        r: 5
                    }, {
                        x: 3,
                        y: 4,
                        r: 5
                    }, {
                        x: 7,
                        y: 5,
                        r: 5
                    },
                ],
                backgroundColor: [
                    '#FBD379',
                ]
            },

        ],
    };
    const dataSignalStrength = {
        datasets: [
            {
                label: ['Signal Strength'],
                data: [{
                    x: 12,
                    y: 10,
                    r: 5
                }, {
                    x: 14,
                    y: 11,
                    r: 5
                }, {
                    x: 17,
                    y: 8,
                    r: 5
                },],
                backgroundColor: '#4D80DD',
            }

        ],
    };
    const dataLevel = {
        datasets: [
            {
                label: ['Level'],
                data: [
                    {
                        x: 12,
                        y: 10,
                        r: 5
                    }, {
                        x: 11,
                        y: 16,
                        r: 5
                    }, {
                        x: 13,
                        y: 8,
                        r: 5
                    },
                ],
                backgroundColor: '#EE6C6C',
            }

        ],
    };
    export default {
        name: 'DevicesDetail',
        data: function () {
            return {
                sensorID:'',
                loaderActive: true,
                deviceDetails:[],
                deviceDetail: {
                    sensorID: 'sigfox-4F45F3B3',
                    displayName: 'Sigfox tilt sensor A',
                    sensorProfile: 'SPEC_CODE_SIGFOX_TILT',
                    type: 'TILT',
                    groupName: 'Project 425',
                    description: 'DC/2019/08 Tsui Ping River Revitalization',
                    calAttributeName: '',
                    calRule: '',
                    customAttributeName: '',
                    customRule: '',
                    customUnit: '',
                    healthSettings: false,
                    expectedMaximumData: '',
                },
                startDate: null,
                endDate: null,
                chData: dataAll,
                chartType: null,
                isLoading: false,
            }
        },
        props: {},
        components: {
            DefaultLayout,
            Header,
            DevicesChart,
            Datepicker,
            Loader
        },
        methods: {
            setChartData(chartData){
                this.isLoading = true;
                this.chartType = chartData;
                switch (chartData) {
                    case 'Last Handler':
                        this.chData = dataLastHandler;
                        break;
                    case 'Signal Strength':
                        this.chData = dataSignalStrength;
                        break;
                    case 'Level':
                        this.chData = dataLevel;
                        break;
                    default:
                        this.chData = dataAll;
                }
                setTimeout(() => {
                    this.isLoading = false
                }, 1500);

            },
        },
        mounted() {
            this.sensorID = this.$route.params.id;
            axios.get("sensors/"+this.sensorID).then((response)=>{
                this.deviceDetails = response.data.data;
                this.loaderActive =false;
            });

        }
    }
</script>

<style>

</style>