<template>
<DefaultLayout>

    <Header header-title="User Group" header-breadcrumb="User / User Group" />
    <div class="main-content user-content">

        <div class="table-heading d-flex justify-content-end">
            <router-link to="/user-group-add-new" class="btn btn-dark mr-3"><i class="fa fa-plus mr-3"></i>Add user group</router-link>
            <a href="#" class="btn btn-dark"><i class="fa fa-filter mr-3"></i> Filter</a>
        </div>
        <div v-if="loaderActive" class="main-card">
            <loader :active="loaderActive" />
        </div>
        <div v-else class="table-responsive mt-5">
            <table class="iot-table table table-hover" id="userGroupTable">
                <thead>
                    <tr>
                        <th></th>
                        <th>Group Name <i class="fa fa-sort"></i></th>
                        <th>Description</th>
                        <th>Device Count</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="userGroup in userGroups" :key="userGroup.id">
                        <td>
                            <span class="table-icon-circle">
                                <img class="" src="../../../assets/img/icons/user-group-icon-2.svg" alt="Icon">
                            </span>
                        </td>
                        <td>
                            <router-link :to="'/user-group-detail/'+userGroup.id">{{ userGroup.name }}</router-link>
                        </td>
                        <td>{{ userGroup.description }}</td>
                        <td>{{ userGroup.sensor_count }}</td>
                        <td>
                            <router-link class="action-link" :to="'/user-group-detail-edit/'+userGroup.id">
                                <img class="" src="../../../assets/img/icons/edit-icon.svg" alt="Icon">
                            </router-link>
                            <router-link class="action-link" to="/">
                                <img class="" src="../../../assets/img/icons/copy-icon.svg" alt="Icon">
                            </router-link>
                            <button @click="deleteUserGroup(userGroup.id)" class="action-link delete-btn"><img src="../../../assets/img/icons/delete-icon.svg" alt="Icon" /></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</DefaultLayout>
</template>

<script>
import DefaultLayout from '@/components/DefaultLayout.vue'
import Header from '@/components/Header.vue'
import Loader from '@/components/Loader.vue'
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import axios from 'axios'
export default {
    name: 'UserGroup',
    data: function () {
        return {
            loaderActive: true,
            userGroups: []
        }
    },
    components: {
        DefaultLayout,
        Header,
        Loader
    },
    methods: {
        getUserGroups() {
            axios.get("groups/sensor_count").then((response) => {
                console.log(response.data);
                this.userGroups = response.data.data;
            }).finally(() => {

                this.loaderActive = false;
                setTimeout(() => {
                    if(jQuery(window).width() < 767){
                        var contentHeaderHeight = 0;
                        jQuery("#userGroupTable").DataTable({
                            pageLength: 25,
                            searching: false,
                            bLengthChange: false,
                            fixedHeader: {
                                header: false,
                                headerOffset: contentHeaderHeight
                            }
                        });
                    }else{
                        var contentHeaderHeight = jQuery('.content-header').height() + 90;
                        jQuery("#userGroupTable").DataTable({
                            pageLength: 25,
                            searching: false,
                            bLengthChange: false,
                            fixedHeader: {
                                header: true,
                                headerOffset: contentHeaderHeight
                            }
                        });
                    }


                }, 1000);
            });
        },

        deleteUserGroup(groupID) {
            if(confirm("Are you sure"))
            axios.delete("groups/"+groupID).then((response) => {
                createToast('User group deleted successfully', {
                            position: 'bottom-right',
                            showIcon: 'true',
                            type: 'danger',
                            transition: 'zoom',
                        });
                jQuery("#userGroupTable").DataTable().destroy();
                this.getUserGroups();
            });
        }
    },
    mounted() {
        this.getUserGroups();
    },
    beforeUnmount() {
        jQuery("#userGroupTable").DataTable().destroy();
    },
}
</script>
