<template>
    <div class="main">
        <div class="container-fluid">
            <div class="main-content-wrapper">
                <div class="content-left">
                    <SidebarNav></SidebarNav>
                </div>
                <div class="content-right">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarNav from '@/components/SidebarNav.vue'
export default {
    name: 'DefaultLayout',
    components: {
        SidebarNav
    }
}
</script>


