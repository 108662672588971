<template>
    <DefaultLayout>
        <Header back-link="/devices" header-title="Device Detail"
                header-breadcrumb="Sensor / Devices / Device Detail"/>
        <div class="main-content device-detail-table-view">
            <div v-if="loaderActive" class="main-card">
                <loader :active="loaderActive" />
              </div> 
            <div v-else>

                <h2 class="device-detail-title">Water Level A</h2>
                <div class="row justify-content-center">
                    <div :class="sidebarToggle ? 'col-md-8 sidebar-active': 'col-md-12'">
                        <div class="card device-detail-card">
                            <ul>
                                <li><p><b>Sensor ID :</b></p>
                                    <p>{{ deviceTableView.sensorID }}</p></li>
                                <li><p><b>Display Name :</b></p>
                                    <p>{{ deviceTableView.displayName }}</p></li>
                                <li><p><b>Sensor Profile :</b></p>
                                    <p>{{deviceTableView.sensorProfile }}</p></li>
                                <li><p><b>Type :</b></p>
                                    <p>{{ deviceTableView.type }}</p></li>
                            </ul>
                            <ul>
                                <li><p><b>Group name :</b></p>
                                    <p>{{ deviceTableView.groupName }}</p></li>
                                <li><p><b>Description :</b></p>
                                    <p>{{ deviceTableView.description }}</p></li>
                            </ul>
                            <router-link class="link" :to="'/devices-detail-config/'+sensorID"><i class="fa fa-angle-right"></i>
                            </router-link>
                        </div>
                        <hr class="my-5">
                        <div class="reading-summary d-flex justify-content-between align-items-center mt-5">
                            <h4>Readings Summary</h4>
                            <div class="d-flex align-items-center justify-content-between">
                                <span class="search-icon mr-3"><img src="../../../assets/img/icons/search-icon.svg" alt="Icon"></span>
                                <Datepicker class="mr-3" v-model="startDate"></Datepicker>
                                <span class="mr-3">TO</span>
                                <Datepicker v-model="endDate"></Datepicker>
                            </div>
                        </div>
                        <div class="table-view devices-chart-modal mt-5" style="height: 400px">
                            <div class="row">
                                <div class="col-md-12">
                                    <div v-if="chartType == 'Last Handler'" class="cross-line last-handler"></div>
                                    <div v-else-if="chartType == 'Signal Strength'" class="cross-line signal-strength"></div>
                                    <div v-else-if="chartType == 'Level'" class="cross-line level"></div>
                                    <div v-else class="cross-line"></div>
                                    <div class="position-relative">
                                        <DevicesChart v-if="true" :class="{'is-loading': isLoading}" :chData="chData"></DevicesChart>
                                        <div class="itl-loader" :class="[isLoading ? 'active':'']"><i class="fa fa-spin fa-spinner"></i></div>
                                    </div>
                                </div>
                            </div>
                            <div class="chart-links">
                                <button :class="{ active: ('Last Handler' == activeTab) }" :disabled="isLoading" @click.prevent="setChartData('Last Handler')"><span
                                    class="yellow"></span>Last Handler
                                </button>
                                <button :class="{ active: ('Signal Strength' == activeTab) }" :disabled="isLoading" @click.prevent="setChartData('Signal Strength')"><span
                                    class="blue"></span>Signal Strength
                                </button>
                                <button :class="{ active: ('Level' == activeTab) }" :disabled="isLoading" @click.prevent="setChartData('Level')"><span
                                    class="orange"></span>Level
                                </button>
                            </div>
                        </div>
                    </div>
                    <div :class="!sidebarToggle ? 'close': ''" class="col-md-4">
                        <div :class="!sidebarToggle ? 'close': ''" class="right-sidebar">
                            <div class="sidebar-header">
                                <h2>Water Level A <span @click="sidebarToggle = false" class="icon-close ml-3"><i
                                    class="fa fa-angle-right"></i></span></h2>
                            </div>
                            <div class="sidebar-body">
                                <div class="position-relative">
                                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                                        <li v-for="(item, index) in navItems" :key="index" class="nav-item">
                                            <button :disabled="isLoading" class="nav-link" :class="{ active: (item.name == activeTab) }"
                                                    @click.prevent="setActiveTab(item.name)">{{ item.name }}</button>
                                        </li>
                                    </ul>
                                    <div class="dropdown">
                                        <button class="btn " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fa fa-ellipsis-v"></i>
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <router-link class="dropdown-item"  to="/device-detail-readings">Details</router-link>
                                            <a href="#" @click="exportData(tabContentData)" class="dropdown-item">Export CSV</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" role="tabpanel">
                                        <table class="table">
                                            <thead>
                                            <th>Strength</th>
                                            <th>Value</th>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(data,index) in tabContentData" :key="index">
                                                <td>{{ data.strength }}</td>
                                                <td>{{ data.value }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </DefaultLayout>
</template>

<script>

    import DefaultLayout from '@/components/DefaultLayout.vue'
    import Header from '@/components/Header.vue'
    import Loader from '@/components/Loader.vue'
    import DevicesChart from '@/components/DevicesChart.vue'
    import Datepicker from '@vuepic/vue-datepicker'
    import '@vuepic/vue-datepicker/dist/main.css'
    import exportFromJSON from "export-from-json";


    const dataAll = {
        datasets: [
            {
                label: ['Last Handler'],
                data: [{
                    x: 4,
                    y: 7,
                    r: 5
                }, {
                    x: 3,
                    y: 4,
                    r: 5
                }, {
                    x: 7,
                    y: 5,
                    r: 5
                },],
                backgroundColor: [
                    '#FBD379',
                ]
            },
            {
                label: ['Signal Strength'],
                data: [{
                    x: 12,
                    y: 10,
                    r: 5
                }, {
                    x: 14,
                    y: 11,
                    r: 5
                }, {
                    x: 17,
                    y: 8,
                    r: 5
                },],
                backgroundColor: '#4D80DD',
            },
            {
                label: ['Level'],
                data: [{
                    x: 12,
                    y: 10,
                    r: 5
                }, {
                    x: 11,
                    y: 16,
                    r: 5
                }, {
                    x: 13,
                    y: 8,
                    r: 5
                },],
                backgroundColor: '#EE6C6C',
            }

        ],
    };
    const dataLastHandler = {
        datasets: [
            {
                label: ['Last Handler'],
                data: [
                    {
                        x: 4,
                        y: 7,
                        r: 5
                    }, {
                        x: 3,
                        y: 4,
                        r: 5
                    }, {
                        x: 7,
                        y: 5,
                        r: 5
                    },
                ],
                backgroundColor: [
                    '#FBD379',
                ]
            },

        ],
    };
    const dataSignalStrength = {
        datasets: [
            {
                label: ['Signal Strength'],
                data: [{
                    x: 12,
                    y: 10,
                    r: 5
                }, {
                    x: 14,
                    y: 11,
                    r: 5
                }, {
                    x: 17,
                    y: 8,
                    r: 5
                },],
                backgroundColor: '#4D80DD',
            }

        ],
    };
    const dataLevel = {
        datasets: [
            {
                label: ['Level'],
                data: [
                    {
                        x: 12,
                        y: 10,
                        r: 5
                    }, {
                        x: 11,
                        y: 16,
                        r: 5
                    }, {
                        x: 13,
                        y: 8,
                        r: 5
                    },
                ],
                backgroundColor: '#EE6C6C',
            }

        ],
    };

    const lastHandlerTabData = [
        {
            'strength': 5,
            'value': 12.57
        },
        {
            'strength': 10,
            'value': 24.678
        },
        {
            'strength': 15,
            'value': 36.325
        },
        {
            'strength': 20,
            'value':48.221
        },
        {
            'strength': 25,
            'value':60.322
        },
        {
            'strength': 30,
            'value':72.456
        },
        {
            'strength': 35,
            'value':84.165
        },
        {
            'strength': 40,
            'value':96.627
        },
        {
            'strength': 45,
            'value':108.812
        },
        {
            'strength': 50,
            'value':120.621
        },
        {
            'strength': 55,
            'value':60.322
        },
        {
            'strength': 60,
            'value':72.456
        },
    ];
    const signalStrengthData = [
        {
            'strength': 523,
            'value': 13
        },
        {
            'strength': 10,
            'value': 24.678
        },
        {
            'strength': 15,
            'value': 36.325
        },
        {
            'strength': 20,
            'value':48.221
        },
        {
            'strength': 25,
            'value':60.322
        },
        {
            'strength': 30,
            'value':72.456
        },
        {
            'strength': 35,
            'value':84.165
        },
        {
            'strength': 40,
            'value':96.627
        },
        {
            'strength': 45,
            'value':108.812
        },
        {
            'strength': 50,
            'value':120.621
        },
        {
            'strength': 55,
            'value':60.322
        },
        {
            'strength': 60,
            'value':72.456
        },
    ];
    const levelTabData = [
        {
            'strength': 5,
            'value': 12.57
        },
        {
            'strength': 10,
            'value': 24.678
        },
        {
            'strength': 15,
            'value': 36.325
        },
        {
            'strength': 20,
            'value':48.221
        },
        {
            'strength': 25,
            'value':60.322
        },
        {
            'strength': 30,
            'value':72.456
        },
        {
            'strength': 35,
            'value':84.165
        },
        {
            'strength': 40,
            'value':96.627
        },
        {
            'strength': 45,
            'value':108.812
        },
        {
            'strength': 50,
            'value':120.621
        },
        {
            'strength': 55,
            'value':60.322
        },
        {
            'strength': 60,
            'value':72.456
        },
    ];

    export const excelParser = () => {
        function exportDataFromJSON(data, newFileName, fileExportType) {
            if (!data) return;
            try {
                const fileName = newFileName || "exported-data";
                const exportType = exportFromJSON.types[fileExportType || "csv"];
                exportFromJSON({ data, fileName, exportType });
            } catch (e) {
                throw new Error("Parsing failed!");
            }
        }

        return {
            exportDataFromJSON
        };
    };
    export default {
        name: 'DevicesDetailTableView',
        data: function () {
            return {
                loaderActive: true,
                sensorID:'',
                deviceTableView: {
                    sensorID: '',
                    displayName: '',
                    sensorProfile: '',
                    type: '',
                    groupName: 'Project 425',
                    description: 'DC/2019/08 Tsui Ping River Revitalization',
                    calAttributeName: 'Level',
                    calRule: 'water_level - ground_level + 82',
                    customAttributeName: 'Level',
                    customRule: 'water_level - ground_level + 82',
                    customUnit: '34',
                    healthSettings : true,
                    expectedMaximumData : '6',
                },
                startDate: null,
                endDate: null,
                chartClasses: 'table-view',
                sidebarToggle: true,
                chData: dataLastHandler,
                chartType: "Last Handler",
                isLoading: false,
                activeTab: 'Last Handler',
                navItems: [
                    {"name": "Last Handler"},
                    {"name": "Signal Strength"},
                    {"name": "Level"}
                ],
                tabContentData: lastHandlerTabData,
            }
        },
        components: {
            DefaultLayout,
            Header,
            DevicesChart,
            Datepicker,
            Loader,
            exportFromJSON
        },
        methods: {
            getSensorData()
            {
                axios.get("sensors/"+this.sensorID).then((response)=>{
                    console.log(response.data);
                    this.deviceTableView.sensorID=response.data.data.sensor_id;
                    this.deviceTableView.displayName=response.data.data.display_name;
                    this.deviceTableView.sensorProfile=response.data.data.profile_name;
                    this.deviceTableView.type=response.data.data.sensor_type;
                this.loaderActive =false;
            });
            },
            setChartData(chartData){
                this.isLoading = true;
                this.sidebarToggle = true;
                this.chartType = chartData;
                switch (chartData) {
                    case 'Last Handler':
                        this.chData = dataLastHandler;
                        this.activeTab = 'Last Handler';
                        this.tabContentData = lastHandlerTabData;
                        break;
                    case 'Signal Strength':
                        this.chData = dataSignalStrength;
                        this.activeTab = 'Signal Strength';
                        this.tabContentData = signalStrengthData;
                        break;
                    case 'Level':
                        this.chData = dataLevel;
                        this.activeTab = 'Level';
                        this.tabContentData = levelTabData;
                        break;
                    default:
                    // code block
                }
                setTimeout(() => {
                    this.isLoading = false
                }, 1500);

            },
            setActiveTab(item){
                this.activeTab= item;
                this.setChartData(item);
                if(item == "Last Handler"){
                    this.tabContentData = lastHandlerTabData
                }
                if(item == "Signal Strength"){
                    this.tabContentData = signalStrengthData
                }
                if(item == "Level"){
                    this.tabContentData = levelTabData
                }
            },
            exportData(data) {
                excelParser().exportDataFromJSON(data, null, null);
            },
        },
        mounted() {
            this.sensorID = this.$route.params.id;
            this.getSensorData();
        }
    }
</script>

<style>

</style>