<template>
<DefaultLayout>
    <Header header-title="Profile" header-breadcrumb="Sensor / Profile" />
    <div class="main-content">
        <div class="table-heading d-flex justify-content-end">
            <div class="wrapper">
                <router-link to="/profile-add-new" class="btn btn-dark mr-3"><i class="fa fa-plus mr-3"></i>Add Profile</router-link>
                <a href="#" class="btn btn-dark"><i class="fa fa-filter mr-3"></i> Filter</a>
            </div>
        </div>
        <div v-if="loaderActive" class="main-card">
            <loader :active="loaderActive" />
        </div>
        <div v-else class="table-responsive mt-2">
            <table class="iot-table table table-hover" id="profileTable">
                <thead>
                    <tr>
                        <th></th>
                        <th>Profile Name <i class="fa fa-sort"></i></th>
                        <th>Attributes Count</th>
                        <th>Linked Sensor</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(profile,index) in profiles" :key="index">
                        <td>
                            <span class="table-icon-circle">
                                <img class="" src="../../../assets/img/icons/copy-icon-circle-blue.svg" alt="Icon">
                            </span>
                        </td>
                        <td>
                            <router-link :to="'/profile-detail/'+profile.id">{{ profile.profile_name }}</router-link>
                        </td>
                        <td>{{ profile.attributes.length }}</td>
                        <td>{{ profile.sensor_count }}</td>
                        <td>
                            <div class="d-flex">
                                <router-link class="action-link" :to="'/profile-detail-edit/'+profile.id">
                                    <img class="" src="../../../assets/img/icons/edit-icon.svg" alt="Icon">
                                </router-link>
                                <a href="#" class="action-link">
                                    <img class="" src="../../../assets/img/icons/copy-icon.svg" alt="Icon">
                                </a>
                                <button type="button" @click="deleteProfile(profile.id)" class="action-link btn-delete">
                                    <img src="../../../assets/img/icons/delete-icon.svg" alt="Icon">
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</DefaultLayout>
</template>

<script>
import DefaultLayout from '@/components/DefaultLayout.vue'
import Header from '@/components/Header.vue'
import Loader from '../../../components/Loader.vue'
import axios from 'axios'

export default {
    name: 'HomeView',
    data: function () {
        return {
            loaderActive: true,
            profiles: [],
        }
    },
    components: {
        DefaultLayout,
        Header,
        Loader
    },
    methods: {
        getProfiles() {
            axios.get("sensors/profiles/sensor_count").then((response) => {
                console.log(response.data);
                if (response.data)
                    this.profiles = response.data.data;
            }).finally(() => {
                var contentHeaderHeight = jQuery('.content-header').height() + 90;
                this.loaderActive = false;
                setTimeout(() => {
                    if(jQuery(window).width() < 767){
                        jQuery("#profileTable").DataTable({
                            pageLength: 25,
                            searching: false,
                            bLengthChange: false,
                            fixedHeader:{
                                header: false,
                                headerOffset: contentHeaderHeight
                            }
                        });
                    }else{
                        jQuery("#profileTable").DataTable({
                            pageLength: 25,
                            searching: false,
                            bLengthChange: false,
                            fixedHeader:{
                                header: true,
                                headerOffset: contentHeaderHeight
                            }
                        });
                    }

                }, 10);
                if(jQuery(window).width() < 767){
                    console.log("mobile");

                }
            });
        },
        deleteProfile(profileID) {
            if (confirm("Are you sure to delete!")) {

                axios.delete("sensors/profiles/" + profileID).then((response) => {
                    alert("Profile deleted successfully");
                    jQuery("#profileTable").DataTable().destroy();
                    this.getProfiles();
                });
            }
        }
    },
    mounted() {

        this.getProfiles();

    },
    beforeUnmount() {
        jQuery("#profileTable").DataTable().destroy();
    },

}
</script>
