<template>
    <div class="sidebar">
        <div class="logo-main">
            <a href="/"><img src="../assets/img/logo-1.png" alt="Logo"></a>
        </div>
        <div class="main-nav">
            <div id="accordion">
                <div class="card user">
                    <div class="card-header" id="userHeading">
                        <h5 class="mb-0">
                            <button class="btn btn-link " :class="[$route.path == '/user-group' || $route.path =='/user-group-add-new' ? '':'collapsed' ]"  data-toggle="collapse"
                                    data-target="#user" aria-expanded="false" aria-controls="user">
                      <span class="d-flex">
                        <img class="icon-gray" src="../assets/img/icons/user-icon.svg" alt="Icon">
                        <img class="icon-color" src="../assets/img/icons/user-icon-red.svg" alt="Icon">

                        User
                      </span>
                                <span class="icon-up">
                        <i class="fa fa-angle-up"></i>
                      </span>
                                <span class="icon-down">
                        <i class="fa fa-angle-down"></i>
                      </span>
                            </button>
                        </h5>
                    </div>
                    <div id="user" class="collapse" :class="{'show': $route.path == '/user-group' 
                    || $route.path =='/user-group-add-new'
                    || $route.name =='UserGroupDetail'
                }"  aria-labelledby="userHeading"
                         data-parent="#accordion">
                        <div class="card-body">
                            <ul>
                                <li>
                                    <router-link class="sub-link" :class="{'active': $route.path == '/user-group' 
                                    || $route.path =='/user-group-add-new'
                                    || $route.name =='UserGroupDetail'
                                }" to="/user-group"><span><i class="fa fa-users"></i></span> User Group</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card sensor">
                    <div class="card-header" id="sensorHeading">
                        <h5 class="mb-0">
                            <button class="btn btn-link" :class="[$route.path == '/' 
                            || $route.path == '/devices' 
                            || $route.name == 'DeviceDetailConfig'
                            || $route.name == 'DevicesDetailReadings'
                            || $route.name == 'ProfileDetail'
                            || $route.name == 'ProfileDetailEdit' 
                            || $route.name == 'ProfileAddNew' 
                            || $route.name == 'DevicesDetailTableView' 
                            || $route.path == '/profile-add-new' ? '':'collapsed' ]" data-toggle="collapse"
                                    data-target="#sensor" aria-expanded="false" aria-controls="sensor">
                      <span class="d-flex">
                        <img class="icon-gray" src="../assets/img/icons/sensor-icon-main-gray.svg" alt="Icon">
                        <img class="icon-color" src="../assets/img/icons/sensor-icon-main-blue.svg" alt="Icon">
                        Sensor
                      </span>
                        <span class="icon-up">
                        <i class="fa fa-angle-up"></i>
                      </span>
                                <span class="icon-down">
                        <i class="fa fa-angle-down"></i>
                      </span>
                            </button>
                        </h5>
                    </div>

                    <div id="sensor" class="collapse" :class="{'show': $route.path == '/' 
                    || $route.name == 'DeviceDetail' 
                    || $route.name == 'ProfileDetail' 
                    || $route.name == 'ProfileAddNew'
                    || $route.name == 'ProfileDetailEdit'
                    || $route.name == 'DeviceDetailEdit' 
                    || $route.path == '/devices' 
                    || $route.path == '/devices-add-new' 
                    || $route.path == '/profile-add-new'
                    || $route.path =='/devices-detail-config'
                    || $route.path =='/device-detail-readings'
                    || $route.name == 'DeviceDetailConfig'
                    || $route.name == 'DevicesDetailTableView'
                
                }" aria-labelledby="sensorHeading"
                         data-parent="#accordion">
                        <div class="card-body">
                            <ul>
                                <li>
                                    <router-link class="sub-link" :class="{'active': $route.path == '/'
                                     || $route.path == '/profile-add-new'
                                     || $route.name == 'ProfileDetail'
                                     || $route.name == 'ProfileDetailEdit'
                                    }" to="/">
                                        <span class="d-flex">
                                            <img class="icon-gray" src="../assets/img/icons/profile-icon-gray.svg" alt="Icon">
                                            <img class="icon-color" src="../assets/img/icons/profile-icon-blue.svg" alt="Icon">
                                            Profiles
                                        </span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link class="sub-link" :class="{'active':  $route.path == '/devices' 
                                    || $route.path == '/devices-add-new' 
                                    || $route.name=='DeviceDetail'
                                    || $route.path=='/devices-detail-config'
                                    || $route.path=='/device-detail-readings'
                                    || $route.name == 'DeviceDetailConfig'
                                    || $route.name == 'DevicesDetailReadings'
                                    || $route.name == 'DeviceDetailEdit'
                                    || $route.name == 'DevicesDetailTableView'
                                }" to="/devices">
                                            <span class="d-flex">
                                                <img class="icon-gray" src="../assets/img/icons/devices-icon-gray.svg" alt="Icon">
                                                <img class="icon-color" src="../assets/img/icons/devices-icon-blue.svg" alt="Icon">
                                                Devices
                                            </span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card cctv">
                    <div class="card-header" id="cctvHeading">
                        <h5 class="mb-0">
                            <button class="btn btn-link collapsed" data-toggle="collapse"
                                    data-target="#cctv" aria-expanded="false"
                                    aria-controls="cctvHeading">
                                <span class="d-flex">
                                    <img class="icon-gray" src="../assets/img/icons/cctv-icon-gray.svg" alt="Icon">
                                    <img class="icon-color" src="../assets/img/icons/cctv-icon-blue.svg" alt="Icon">
                                    CCTV
                              </span>
                                <span class="icon-up">
                        <i class="fa fa-angle-up"></i>
                      </span>
                                <span class="icon-down">
                        <i class="fa fa-angle-down"></i>
                      </span>
                            </button>
                        </h5>
                    </div>
                    <div id="cctv" class="collapse" aria-labelledby="cctvHeading"
                         data-parent="#accordion">
                        <div class="card-body">
                            <ul>
                                <li><a href="#" class="sub-link active"><span><i
                                    class="fa fa-users"></i></span>Link</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card dashboard">
                    <div class="card-header" id="dashboardHeading">
                        <h5 class="mb-0">
                            <button class="btn btn-link collapsed" data-toggle="collapse"
                                    data-target="#dashboard" aria-expanded="false"
                                    aria-controls="dashboardHeading">
                      <span class="d-flex">
                        <img class="icon-gray" src="../assets/img/icons/dashboard-icon.svg" alt="Icon">
                        <img class="icon-color" src="../assets/img/icons/dashboard-icon-blue.svg" alt="Icon">Dashboard</span>
                                <span class="icon-up">
                        <i class="fa fa-angle-up"></i>
                      </span>
                                <span class="icon-down">
                        <i class="fa fa-angle-down"></i>
                      </span>
                            </button>
                        </h5>
                    </div>
                    <div id="dashboard" class="collapse" aria-labelledby="dashboardHeading"
                         data-parent="#accordion">
                        <div class="card-body">
                            <ul>
                                <li><a href="#" class="sub-link active"><span><i
                                    class="fa fa-users"></i></span>Link</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card alert p-0 m-0">
                    <div class="card-header" id="alertHeading">
                        <h5 class="mb-0">
                            <button class="btn btn-link collapsed" data-toggle="collapse"
                                    data-target="#alert" aria-expanded="false"
                                    aria-controls="alertHeading">


                        <span class="d-flex">
                            <img class="icon-gray" src="../assets/img/icons/alert-icon-gray.svg" alt="Icon">
                            <img class="icon-color" src="../assets/img/icons/alert-icon-blue.svg" alt="Icon">
                            Alert
                      </span>

                                <span class="icon-up">
                        <i class="fa fa-angle-up"></i>
                      </span>
                                <span class="icon-down">
                        <i class="fa fa-angle-down"></i>
                      </span>
                            </button>
                        </h5>
                    </div>
                    <div id="alert" class="collapse" aria-labelledby="alertHeading"
                         data-parent="#accordion">
                        <div class="card-body">
                            <ul>
                                <li><a href="#" class="sub-link active"><span><i
                                    class="fa fa-users"></i></span>Link</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card api">
                    <div class="card-header" id="apiHeading">
                        <h5 class="mb-0">
                            <button class="btn btn-link collapsed" data-toggle="collapse"
                                    data-target="#api" aria-expanded="false" aria-controls="apiHeading">
                                <span class="d-flex">
                                    <img class="icon-gray" src="../assets/img/icons/api-icon-gray.svg" alt="Icon">
                                    <img class="icon-color" src="../assets/img/icons/api-icon-blue.svg" alt="Icon">
                                    API
                              </span>
                                <span class="icon-up">
                        <i class="fa fa-angle-up"></i>
                      </span>
                                <span class="icon-down">
                        <i class="fa fa-angle-down"></i>
                      </span>
                            </button>
                        </h5>
                    </div>
                    <div id="api" class="collapse" aria-labelledby="apiHeading"
                         data-parent="#accordion">
                        <div class="card-body">
                            <ul>
                                <li><a href="#" class="sub-link active"><span><i
                                    class="fa fa-users"></i></span>Link</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SidebarNav',
        data: function () {
            return {}
        },
        props: {
//            msg: String
        },
        components: {},
        methods: {},
        mounted() {},
        watch: {},
    }
</script>


