<template>
<DefaultLayout>
    <Header back-link="/devices" header-title="New Device Detail" header-breadcrumb="Sensor / Devices / New Device Detail" />
    <div class="main-content device-add">
        <div class="row justify-content-center">
            <div class="col-md-10">
                <h3 class="title add-device-title">{{ newDevice.device_name }} <span class="ml-3"><a href="#" data-toggle="modal" data-target="#addNewDeviceModal"><i class="fa fa-pencil-alt"></i></a></span></h3>
                <div class="card device-detail-card">
                    <ul>
                        <li>
                            <p><b>Sensor ID :</b></p>
                            <p><input v-model="newDevice.sensor_id" type="text" class="form-control"></p>
                        </li>
                        <li>
                            <p><b>Display Name :</b></p>
                            <p><input v-model="newDevice.display_name" type="text" class="form-control"></p>
                        </li>
                        <li>
                            <p><b>Sensor Profile :</b></p>
                            <p class="position-relative">
                                <input v-model="newDevice.profile_name" type="text" class="form-control">
                                <span class="search-icon"><img src="../../../assets/img/icons/search-icon.svg" alt="Icon"></span>
                            </p>
                        </li>
                        <li>
                            <p><b>Type :</b></p>
                            <p>
                                <select v-model="newDevice.sensor_type" class="form-control" name="" id="">
                                    <Option selected>Options</Option>
                                    <Option value="TILT1">TILT</Option>
                                    <Option value="TILT2">TILT1</Option>
                                </select>
                            </p>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <p><b>Group name :</b></p> <input v-model="addNewDevice.groupName" type="text" class="form-control">
                        </li>
                        <li>
                            <p><b>Description :</b></p> <input v-model="addNewDevice.description" type="text" class="form-control">
                        </li>
                    </ul>
                </div>
                <div id="accordion" class="iot-accordion profile-accordion mt-5">
                    <div class="card">
                        <div class="card-header" id="headingOne">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    <span>
                                        Calibration
                                    </span>
                                    <span class="">
                                        <span class="icon-up">
                                            <i class="fa fa-angle-up"></i>
                                        </span>
                                        <span class="icon-down">
                                            <i class="fa fa-angle-down"></i>
                                        </span>
                                    </span>
                                </button>
                            </h5>
                        </div>
                        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                            <div class="card-body">
                                <ul>
                                    <li>
                                        <p>
                                            <span>Attribute Name: </span>
                                            <span><input v-model="addNewDevice.calAttributeName" type="text" class="form-control"></span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span>Rule : </span>
                                            <span>
                                                <input v-model="addNewDevice.calRule" type="text" class="form-control">
                                            </span>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingTwo">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <span>
                                        Custom Attributes
                                    </span>
                                    <span class="">
                                        <span class="icon-up">
                                            <i class="fa fa-angle-up"></i>
                                        </span>
                                        <span class="icon-down">
                                            <i class="fa fa-angle-down"></i>
                                        </span>
                                    </span>
                                </button>
                            </h5>
                        </div>
                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                            <div class="card-body">
                                <ul>
                                    <li>
                                        <p>
                                            <span>Attribute Name: </span>
                                            <span><input v-model="addNewDevice.customAttributeName" type="text" class="form-control"></span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span>Rule : </span>
                                            <span><input v-model="addNewDevice.customRule" type="text" class="form-control"></span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span>Unit : </span>
                                            <span><input v-model="addNewDevice.customUnit" type="text" class="form-control"></span>
                                        </p>
                                    </li>
                                </ul>
                                <a href="#" class="btn btn-dark"><i class="fa fa-plus mr-3"></i> Add Attributes</a>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingThree">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <span>
                                        Health Setting
                                    </span>
                                    <span class="">
                                        <span class="icon-up">
                                            <i class="fa fa-angle-up"></i>
                                        </span>
                                        <span class="icon-down">
                                            <i class="fa fa-angle-down"></i>
                                        </span>
                                    </span>
                                </button>
                            </h5>
                        </div>
                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                            <div class="card-body">
                                <div class="custom-control custom-switch">
                                    <input v-model="addNewDevice.healthSettings" type="checkbox" class="custom-control-input" id="customSwitch1">
                                    <label class="custom-control-label" for="customSwitch1">Toggle this switch element</label>
                                </div>
                                <div class="ml-2 mt-4 d-flex align-items-center">
                                    <p>
                                        <small><b>Expected Maximum data updating span in seconds</b></small> :
                                    </p>
                                    <p class="ml-3">
                                        <input v-model="addNewDevice.expectedMaximumData" type="text" class="form-control">
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="action-btn text-right mt-5">
            <button @click="addNewSensor()" class="btn btn-dark mr-3">Save</button>
            <a href="#" @click="discard" class="btn btn-dark">Discard</a>
        </div>

    </div>
</DefaultLayout>
<!-- Modal -->
<div class="modal fade iot-modal add-new-device-modal" id="addNewDeviceModal" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    Edit Device Name
                </h5>
            </div>
            <div class="modal-body">
                <form>
                    <div class="form-group">
                        <label for="userGroup" class="col-form-label"><b>Device Name:</b></label>
                        <input v-model="newDevice.device_name" type="text" class="form-control" id="userGroup">
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-dark" data-dismiss="modal">Save</button>
                <button type="button" @click="discardDeviceNameChange()" class="btn btn-dark" data-dismiss="modal">Discard</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import DefaultLayout from '@/components/DefaultLayout.vue'
import Header from '@/components/Header.vue'
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import axios from 'axios'
export default {
    name: 'DevicesAddNew',
    data: function () {
        return {
            newDevice: {
                device_name: 'New Device',
                sensor_id: '',
                display_name: '',
                profile_name: '',
                sensor_type: '',
            },
            addNewDevice: {
                groupName: 'Project 425',
                description: 'DC/2019/08 Tsui Ping River Revitalization',
                calAttributeName: '',
                calRule: '',
                customAttributeName: '',
                customRule: '',
                customUnit: '',
                healthSettings: false,
                expectedMaximumData: '',
            },
        }
    },
    components: {
        DefaultLayout,
        Header,
    },
    created() {
        this.deviceNameBeforeEdit = this.addNewDevice.deviceName
    },
    methods: {
        discard() {
            this.$router.push("/devices");
        },
        discardDeviceNameChange() {
            this.addNewDevice.deviceName = this.deviceNameBeforeEdit
        },
        addNewSensor() {
            axios.post("sensors/", this.newDevice).then((response) => {
                if (response.data.status == 'SUCCESS') {
                    createToast('Device is added successfully', {
                        position: 'bottom-right',
                        showIcon: 'true',
                        type: 'success',
                        transition: 'zoom',
                    });
                }
            })
        }
    },
    mounted() {}
}
</script>
