<template>
    <DefaultLayout>
        <div class="main-content profile-detail">
            <Header back-link="/" header-title="Profile Detail" header-breadcrumb="Sensor / Profile / Profile Detail" />
            <div class="row justify-content-center">
                <div v-if="loaderActive" class="main-card">
                    <loader :active="loaderActive" />
                </div>
                <div v-else class="col-md-10">

                    <div class="content-basic-info">
                        <h2 class="profile-detail-title">{{profileDetail.profile_name}}</h2>
                        <p><strong>Message Type:</strong> <span class="ml-3">{{ profileDetail.msg_type }}</span></p>
                        <router-link :to="'/profile-detail-edit/'+profileDetail.id" class="btn btn-dark">Edit</router-link>
                    </div>
                    <div id="accordion" class="iot-accordion profile-accordion">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne">
                                    <span>Attributes <span class="tooltip-icon"><img
                                        src="../../../assets/img/icons/help-icon.svg" alt="Icon"></span></span>
                                        <span class="">
                                        <span class="icon-up">
                                            <i class="fa fa-angle-up"></i>
                                        </span>
                                        <span class="icon-down">
                                            <i class="fa fa-angle-down"></i>
                                        </span>
                                    </span>
                                    </button>
                                    <span class="search-input">
                                    <input type="text" placeholder="Search Attribute">
                                    <span class="search-icon"><img src="../../../assets/img/icons/search-icon.svg"
                                                                   alt="Search Icon"></span>
                                </span>
                                </h5>
                            </div>

                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                 data-parent="#accordion">
                                <div class="card-body">
                                    <ul v-for="(item,index) in profileDetail.attributes" :key="index">
                                        <li>
                                            <p>
                                                <span>Attribute Name:</span>
                                                <span>{{ item.attribute_name }}</span>
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <span>Data Type :</span>
                                                <span>{{ item.data_type }}</span>
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <span>Quantity Name :</span>
                                                <span v-if="item.unit!=null">{{ item.unit.quantity_name }}</span>
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <span>Rule :</span>
                                                <span v-if="item.unit!=null">{{ item.unit.unit }}</span>
                                            </p>
                                        </li>
                                        <hr/>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <h5 class="mb-0">
                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo"
                                            aria-expanded="false" aria-controls="collapseTwo">
                                    <span>Icons <span class="tooltip-icon"><img src="../../../assets/img/icons/help-icon.svg"
                                                                                alt="Icon"></span></span>
                                        <span class="">
                                        <span class="icon-up">
                                            <i class="fa fa-angle-up"></i>
                                        </span>
                                        <span class="icon-down">
                                            <i class="fa fa-angle-down"></i>
                                        </span>
                                    </span>
                                    </button>
                                    <!--<span class="search-input">-->
                                    <!--<input type="text" placeholder="Search Attribute">-->
                                    <!--<span class="search-icon"><i class="fa fa-search"></i></span>-->
                                    <!--</span>-->
                                </h5>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                <div class="card-body">
                                    <ul>
                                        <li>
                                            <p>
                                                <span>Category</span>
                                                <span><i class="fa fa-satellite-dish mr-2"></i>missing</span>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/components/DefaultLayout.vue'
import Header from '@/components/Header.vue'
import Loader from '@/components/Loader.vue'
import axios from 'axios'
export default {
    name: 'ProfileDetail',
    data: function () {
        return {
            loaderActive: true,
            profileDetail: [],
        }
    },
    components: {
        DefaultLayout,
        Header,
        Loader,
    },
    mounted() {
        let profileID = this.$route.params.id;
        axios.get("sensors/profiles/"+profileID).then((response)=>{
            this.profileDetail = response.data.data;
            this.loaderActive=false;
        });
    }
}
</script>