import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Datepicker from '@vuepic/vue-datepicker';

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../src/assets/css/custom.css'


import '@vuepic/vue-datepicker/dist/main.css'

import './axios'



createApp(App).use(store).use(router).component('Datepicker', Datepicker).mount('#app')


