<template>
    <DefaultLayout>
        <Header header-title="Devices" header-breadcrumb="Sensor / Devices"/>
        <div class="main-content">
            <div class="table-heading d-flex justify-content-end">
                <div class="wrapper">
                    <router-link to="/devices-add-new" class="btn btn-dark mr-3"
                    ><i class="fa fa-plus mr-3"></i>Add Device
                    </router-link
                    >
                    <a href="#" class="btn btn-dark"
                    ><i class="fa fa-filter mr-3"></i> Filter</a
                    >
                </div>
            </div>
            <div class="content-wrapper">
                <div v-if="loaderActive" class="main-card">
                    <loader :active="loaderActive"/>
                </div>

                <div v-if="!loaderActive && allDevices && allDevices.length" class="table-responsive">
                    <table
                        class="iot-table table table-hover"
                        id="userGroupDetailTable"
                    >
                        <thead>
                        <tr>
                            <th style="width: 5%"></th>
                            <th style="width: 20%">Sensor ID <i class="fa fa-sort"></i></th>
                            <th style="width: 15%">Display Name</th>
                            <th style="width: 20%">Profile</th>
                            <th style="width: 15%">Type</th>
                            <th style="width: 15%"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(device, index) in allDevices" :key="index">
                            <td>
                  <span class="table-icon-circle">
                    <img class="" src="../../../assets/img/icons/sensor-icon.svg" alt="Icon">
                  </span>
                            </td>
                            <td>
                                <router-link :to="'/devices-detail/' + device.id">{{
                                    device.sensor_id
                                    }}
                                </router-link>
                            </td>
                            <td>{{ device.display_name }}</td>

                            <td>{{ device.profile_name }}</td>
                            <td>{{ device.sensor_type }}</td>
                            <td>
                                <div class="d-flex">
                                    <router-link
                                        class="action-link"
                                        :to="'/devices-detail-edit/' + device.id"
                                    >
                                        <img class="" src="../../../assets/img/icons/edit-icon.svg" alt="Icon">
                                    </router-link>
                                    <router-link class="action-link" to="/"><img class="" src="../../../assets/img/icons/copy-icon.svg" alt="Icon"></router-link>
                                    <button type="button" @click="deviceDelete(device.id)" class="action-link" to="/"><img src="../../../assets/img/icons/delete-icon.svg" alt="Icon"/></button>
                                </div>

                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </DefaultLayout>
</template>

<script>
    import DefaultLayout from "@/components/DefaultLayout.vue";
    import Header from "@/components/Header.vue";
    import Loader from "@/components/Loader.vue";
    import {
        createToast
    } from 'mosha-vue-toastify';
    import 'mosha-vue-toastify/dist/style.css'
    import axios from "axios";

    export default {
        name: "Devices",
        data: function () {
            return {
                loaderActive: true,
                allDevices: [],
                show: true,
            };
        },
        components: {
            DefaultLayout,
            Header,
            Loader,
        },
        methods: {
            getDevices(){
                axios
                    .get("sensors/info")
                    .then((response) => {
                        this.allDevices = response.data.data;
                    })
                    .finally(() => {
                        this.loaderActive = false;

                        setTimeout(() => {
                            if(jQuery(window).width() < 767){
                                console.log("mobile");
                                var contentHeaderHeight = 0;
                                jQuery("#userGroupDetailTable").DataTable({
                                    pageLength: 25,
                                    searching: false,
                                    bLengthChange: false,
                                    fixedHeader: {
                                        header: false,
                                        headerOffset: contentHeaderHeight
                                    }
                                });
                            }else{
                                var contentHeaderHeight = jQuery('.content-header').height() + 90;
                                jQuery("#userGroupDetailTable").DataTable({
                                    pageLength: 25,
                                    searching: false,
                                    bLengthChange: false,
                                    fixedHeader: {
                                        header: true,
                                        headerOffset: contentHeaderHeight
                                    }
                                });
                            }

                        }, 10);

                    });
            },
            deviceDelete(deviceID){
                if (confirm("Are you sure!")) {
                    axios
                        .delete("sensors/" + deviceID)
                        .then((response) => {
                            createToast('Device deleted successfully', {
                                position: 'bottom-right',
                                showIcon: 'true',
                                type: 'danger',
                                transition: 'zoom',
                            });
                            jQuery("#userGroupDetailTable").DataTable().destroy();
                            this.getDevices();
                        }).catch((errors) => {
                        console.log("some erros occurs")
                    })
                }
            },
        },
        beforeUnmount() {
            jQuery("#userGroupDetailTable").DataTable().destroy();
        },
        mounted() {
            this.getDevices();
        },
        watch: {}
    };
</script>
