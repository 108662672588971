<template>
<DefaultLayout>
    <div class="main-content user-content">
        <Header back-link="/user-group" header-title="User Group Detail" header-breadcrumb="User / User Group / User Group Detail" />
        <div class="table-heading">
            <h2 class="mb-4">{{ userGroupDetail.userGroupName }}</h2>
            <div class="d-flex justify-content-between">
                <div class="description">
                    <p class="mr-4">Description : {{ userGroupDetail.description }}</p>

                </div>
                <div class="action-btn">
                    <router-link class="btn btn-dark mr-3" :to="'/user-group-detail-edit/'+userGroupDetail.id">Edit</router-link>
                </div>
            </div>
        </div>
        <div class="content-wrapper">
            <div class="text-right mt-5">
                <button data-toggle="modal" data-target="#addProjectModal" class="btn btn-light btn-add-modal"><i class="fa fa-plus"></i></button>
            </div>
            <div v-if="loaderActive" class="main-card">
                <loader :active="loaderActive" />
            </div>
            <div v-else-if="sensorsOfGroup.length>0" class="table-responsive">
                <table class="iot-table table table-hover mt-3">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Sensor ID <i class="fa fa-sort"></i></th>
                            <th>Display Name</th>
                            <th>Profile</th>
                            <th>Type</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(sensor,index) in sensorsOfGroup" :key="index">
                            <td>
                                <span class="table-icon-circle">
                                   <img class="" src="../../../assets/img/icons/sensor-icon.svg" alt="Icon">
                                </span>
                            </td>
                            <td>
                                <router-link to="/devices-detail">{{ sensor.sensor_id }}</router-link>
                            </td>
                            <td>{{ sensor.display_name }}</td>
                            <td>{{ sensor.profile_name }}</td>
                            <td>{{ sensor.sensor_type }}</td>
                            <td>
                                <button type="button" @click="removeSensorFromGroup(sensor.id)" class="action-link"><img src="../../../assets/img/icons/icon-close.svg" alt="Icon"></button>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div v-else="" class="no-data">Sensor list is empty</div>
            <div class="user-group-card mt-5 pt-4">
                <h5>User list of the group</h5>
                <div class="table-responsive" v-if="usersOfGroup.length>0">
                    <table class="iot-table table table-hover mt-3" id="">
                        <thead>
                            <tr>
                                <th></th>
                                <th>User ID <i class="fa fa-sort"></i></th>
                                <th>User Name</th>
                                <th>Profile</th>
                                <th>Type</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(user,index) in usersOfGroup" :key="index">
                                <td>
                                    <span class="table-icon-circle">
                                        <img class="" src="../../../assets/img/icons/sensor-icon.svg" alt="Icon">
                                    </span>
                                </td>
                                <td>
                                    <router-link to="/devices-detail">{{ user.user_id }}</router-link>
                                </td>
                                <td>{{ user.display_name }}</td>
                                <td>{{ user.profile_name }}</td>
                                <td>{{ user.sensor_type }}</td>
                                <td>
                                    <button type="button" @click="removeUserFromGroup(user.id)" class="action-link"><img src="../../../assets/img/icons/icon-close.svg" alt="Icon"></button>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div v-else class="no-data">User list of the group is empty</div>
            </div>
        </div>
    </div>
</DefaultLayout>

<!-- Modal -->
<div class="modal fade iot-modal add-project-modal" id="addProjectModal" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header pt-0 pb-0">
                <h5 class="modal-title" id="exampleModalLongTitle">
                    <div class="input-group">
                        <div class="input-group-prepend search-icon">
                            <span class="input-group-text"><img src="../../../assets/img/icons/search-icon.svg" alt="ICON"></span>
                        </div>
                        <input v-model="searchData" type="text" class="form-control" placeholder="search" aria-label="Username" aria-describedby="basic-addon1">
                        <button @click="clearSearch()" class="clear-search">
                            <img src="../../../assets/img/icons/icon-close-white.svg" alt="ICON">
                        </button>
                    </div>
                </h5>

            </div>
            <div class="modal-body">
                <ul class="nav nav-tabs" id="projectTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="all-tab" data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="true">
                            All
                            <span class="badge badge-light">70</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="devices-tab" data-toggle="tab" href="#devices" role="tab" aria-controls="devices" aria-selected="false">
                            <i class="fas fa-desktop"></i>
                            Devices
                            <span class="badge badge-light">{{ devices.length }}</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="user-tab" data-toggle="tab" href="#user" role="tab" aria-controls="user" aria-selected="false">
                            <img src="../../../assets/img/icons/user-icon.svg" alt="Icon">
                            User
                            <span class="badge badge-light">20</span>
                        </a>
                    </li>
                </ul>
                <div class="tab-content" id="projectTabContent">
                    <div class="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                                <h6>Sigfox tilt sensor A</h6>
                                <span>SPEC_CODE_SIGFOX_TILT</span>
                                <button class="btn btn-dark btn-add"><i class="fa fa-plus"></i></button>
                            </li>
                            <li class="list-group-item">
                                <h6>Sigfox tilt sensor C</h6>
                                <span>SPEC_CODE_SIGFOX_TILT</span>
                                <button class="btn btn-dark btn-add"><i class="fa fa-plus"></i></button>
                            </li>
                            <li class="list-group-item">
                                <h6>Sigfox tilt sensor D</h6>
                                <span>SPEC_CODE_SIGFOX_TILT</span>
                                <button class="btn btn-dark btn-add"><i class="fa fa-plus"></i></button>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-pane fade" id="devices" role="tabpanel" aria-labelledby="profile-tab">
                        <ul class="list-group list-group-flush">
                            <li v-for="(device,index) in devices" :key="index" class="list-group-item">
                                <h6>{{device.display_name}}</h6>
                                <span>{{device.profile_name}}</span>
                                <button @click="addSensorToGroup(device.id,groupId)" class="btn btn-dark btn-add"><i class="fa fa-plus"></i></button>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-pane fade" id="user" role="tabpanel" aria-labelledby="user-tab">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                                <h6>Sigfox tilt sensor C</h6>
                                <span>SPEC_CODE_SIGFOX_TILT</span>
                                <button class="btn btn-dark btn-add"><i class="fa fa-plus"></i></button>
                            </li>
                            <li class="list-group-item">
                                <h6>Sigfox tilt sensor D</h6>
                                <span>SPEC_CODE_SIGFOX_TILT</span>
                                <button class="btn btn-dark btn-add"><i class="fa fa-plus"></i></button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import DefaultLayout from '@/components/DefaultLayout.vue'
import Header from '@/components/Header.vue'
import Loader from '@/components/Loader.vue'
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import axios from 'axios'
export default {
    name: 'UserGroupDetail',
    data: function () {
        return {
            groupId: '',
            loaderActive: true,
            userGroupDetail: {
                userGroupName: "",
                description: "",
                id: "",
            },
            devices: [],
            users: [],
            searchData: '',
            sensorsOfGroup: [],
            usersOfGroup: [],
        }
    },
    components: {
        DefaultLayout,
        Header,
        Loader
    },
    methods: {
        clearSearch() {
            this.searchData = "";
        },
        getGroupDetail() {
            axios.get("groups/" + this.groupId).then((response) => {
                this.userGroupDetail.userGroupName = response.data.data.name
                this.userGroupDetail.description = response.data.data.description
                this.userGroupDetail.id = response.data.data.id;
            });

        },
        getGroupSensers() {
            axios.get("sensors/?group_id=" + this.groupId).then((response) => {
                this.sensorsOfGroup = response.data.data;
            }).finally(() => {
                this.loaderActive = false;
            });
        },
        getAllSensors() {
            axios.get("sensors/info").then((response) => {
                this.devices = response.data.data;
            });
        },
        getAllUsers() {
            axios.get("groups/"+this.groupId+"/users").then((response) => {
                this.users = response.data.data;
            });
        },
        getGroupUsers() {
            // axios.get("/groups/" + this.groupId + "/users").then((response) => {
            // console.log("Ssfso", response.data);
            // this.usersOfGroup = response.data.data;
            // });
        },
        addSensorToGroup(sensor_id, groupId) {
            axios.post("sensors/" + sensor_id + "/groups?group_id=" + groupId).then((response) => {
                if (response.data.status == 'SUCCESS')
                    this.getGroupSensers();
            }).catch((errors) => {
                console.log("errors ", errors);
            });
        },
        removeSensorFromGroup(sensor_id) {
            if (confirm("Are you sure"))
                axios.delete("sensors/" + sensor_id + "/groups?group_id=" + this.groupId).then((response) => {
                    if (response.data.status == 'SUCCESS') {
                        this.getGroupSensers();
                        createToast('Senser deleted from user group', {
                            position: 'bottom-right',
                            showIcon: 'true',
                            type: 'danger',
                            transition: 'zoom',
                        });
                    }

                })
        }
    },
    mounted() {
        this.groupId = this.$route.params.id;
        this.getGroupDetail();
        this.getGroupSensers();
        this.getGroupUsers();
        this.getAllSensors();
        this.getAllUsers();
    }
}
</script>
