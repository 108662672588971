<template>
<DefaultLayout>
    <div class="main-content profile-detail">
        <Header :back-link="'/profile-detail/'+editProfile.id" header-title="Profile Detail Edit" header-breadcrumb="Sensor / Profile / Profile Detail Edit" />
        <div class="row justify-content-center">
            <div v-if="loaderActive" class="main-card">
                <loader :active="loaderActive" />
            </div>
            <div v-else class="col-md-10">

                <div class="content-basic-info edit">
                    <h2 class="profile-detail-title">{{ editProfile.profile_name }}</h2>
                    <p>
                        <strong>Message Type:</strong>
                        <span class="ml-3">
                            <select v-model="editProfile.msg_type" name="" id="" class="select-type form-control">
                                <option value="">Option</option>
                                <option value="json">Json</option>
                                <option value="array">Array</option>
                            </select>
                        </span>
                    </p>

                </div>

                <div id="accordion" class="iot-accordion profile-accordion">
                    <div class="card">
                        <div class="card-header" id="headingOne">
                            <h5 class="mb-0">
                                <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <span>Attributes</span>
                                    <span class="">
                                        <span class="icon-up">
                                            <i class="fa fa-angle-up"></i>
                                        </span>
                                        <span class="icon-down">
                                            <i class="fa fa-angle-down"></i>
                                        </span>
                                    </span>
                                </button>
                            </h5>
                        </div>

                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                            <div class="card-body">
                                <ul v-for="(item, index) in editProfile.attributes" :key="index">
                                    <li>
                                        <p>
                                            <span>Attribute Name:</span>
                                            <span><input v-model="item.attribute_name" name="attribute_name" type="text" placeholder="Velocity" class="form-control"></span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span>Data Type :</span>
                                            <span>
                                                <select name="data_type" v-model="item.data_type" class="select-type form-control">
                                                    <option value="float">Float</option>
                                                    <option value="json">Json</option>
                                                    <option value="csv">Csv</option>
                                                </select>
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span>Quantity Name :</span>
                                            <span><input v-model="item.unit.quantity_name" type="text" placeholder="Velocity" class="form-control"></span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span>Rule :</span>
                                            <span><input v-model="item.unit.unit" type="text" placeholder="m/s" class="form-control"></span>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingTwo">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <span>Icons</span>
                                    <span class="">
                                        <span class="icon-up">
                                            <i class="fa fa-angle-up"></i>
                                        </span>
                                        <span class="icon-down">
                                            <i class="fa fa-angle-down"></i>
                                        </span>
                                    </span>
                                </button>
                            </h5>
                        </div>
                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                            <div class="card-body">
                                <ul>
                                    <li>
                                        <p>
                                            <span>Category</span>
                                            <span>
                                                <select v-model="editProfile.category" style="width: fit-content;" name="" class="fa form-control">
                                                    <option class="fa" value="">&#xf7c0; options</option>
                                                    <option class="fa" value="sensor">&#xf7c0; Sensor</option>
                                                    <option class="fa" value="option2">&#xf00c; Option2</option>
                                                    <option class="fa" value="option2">&#xf30b; Option2</option>
                                                </select>
                                            </span>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div v-if="!loaderActive" class="action-btn text-right mt-5">
            <button type="button" @click="editProfileSave()" class="btn btn-save btn-dark mr-3">Save</button>
            <a href="#" @click="discardAllChanges()" class="btn btn-dark">Discard</a>
        </div>
    </div>
</DefaultLayout>
</template>

<script>
import DefaultLayout from '@/components/DefaultLayout.vue'
import Header from '@/components/Header.vue'
import Loader from '@/components/Loader.vue'
import axios from 'axios'
export default {
    name: 'ProfileDetailEdit',
    data: function () {
        return {
            loaderActive: true,
            editProfile: [],
            profileBeforeEdit: {}
        }
    },
    components: {
        DefaultLayout,
        Header,
        Loader
    },
    created() {
        this.profileBeforeEdit = Object.assign({}, this.editProfile)
        this.profileNameBeforeEdit = this.editProfile.profile_name
    },
    methods: {
        discardAllChanges() {
            Object.assign(this.editProfile, this.profileBeforeEdit)
        },
        discardChange() {
            this.editProfile.profile_name = this.profileNameBeforeEdit
        },
        editProfileSave() {
            console.log(this.editProfile);
            axios.patch("sensors/profiles", this.editProfile).then((response) => {
            });
        }
    },
    mounted() {
        let profileID = this.$route.params.id;
        axios.get("sensors/profiles/" + profileID).then((response) => {
            this.editProfile = response.data.data;
            this.loaderActive = false;
        });
    }
}
</script>
