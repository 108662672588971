<template>
    <div class="content-header">
        <div class="content-title">
             <h1>
                 <span v-if="backLink">
                     <!--<router-link :to="backLink"><i class="fa fa-angle-left"></i></router-link>-->
                 </span>
                 <a href="#" @click="$router.go(-1)"><i class="fa fa-angle-left"></i></a>
                 {{ headerTitle }}
             </h1>
            <span>{{ headerBreadcrumb }}</span>
        </div>
        <div class="user-info">
            <div class="icon"><i class="far fa-bell"></i></div>
            <span class="user-name">
                <span class="circle">U</span>
                <span>User</span>s
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Header',
    data: function () {
        return {}
    },
    props: {
        headerTitle: String,
        headerBreadcrumb: String,
        backLink: String,
    },
    components: {},
    methods: {},
    mounted() {
    },
    watch: {},
}
</script>