<template>
<DefaultLayout>
    <div class="main-content profile-detail">
        <Header back-link="/" header-title="New Profile Detail" header-breadcrumb="Sensor / Profile / New Profile Detail" />
        <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="table-heading border-0">
                    <h2 class="mb-4">{{ newProfile.profile_name }}
                        <a href="#" data-toggle="modal" data-target="#editProfileNameModal">
                            <img class="" src="../../../assets/img/icons/edit-icon.svg" alt="Icon">
                        </a>
                    </h2>
                </div>

                <div class="content-basic-info add-profile">
                    <p>
                        <strong>Message Type:</strong>
                        <span class="ml-3">
                            <select v-model="newProfile.msg_type" name="" id="" class="select-type form-control">
                                <option value="">Options</option>
                                <option value="json">Json</option>
                                <option value="array">Array</option>
                            </select>
                        </span>
                    </p>

                </div>

                <div id="accordion" class="iot-accordion profile-accordion">
                    <div class="card">
                        <div class="card-header" id="headingOne">
                            <h5 class="mb-0">
                                <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <span>Attributes</span>
                                    <span class="">
                                        <span class="icon-up">
                                            <i class="fa fa-angle-up"></i>
                                        </span>
                                        <span class="icon-down">
                                            <i class="fa fa-angle-down"></i>
                                        </span>
                                    </span>
                                </button>
                            </h5>
                        </div>

                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                            <div class="card-body">
                                <ul>
                                    <li>
                                        <p>
                                            <span>Attribute Name:</span>
                                            <span><input v-model="newProfile.attributes[0].attribute_name" type="text" placeholder="Velocity" class="form-control"></span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span>Data Type :</span>
                                            <span>
                                                <select v-model="newProfile.attributes[0].data_type" name="" class="select-type form-control">
                                                    <option value="">Options</option>
                                                    <option value="float">Float</option>
                                                    <option value="double">Float</option>
                                                </select>
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span>Quantity Name :</span>
                                            <span class="position-relative">
                                                <select @change="setRule($event)" v-model="newProfile.attributes[0].unit.quantity_name" style="width: fit-content;" class="fa form-control">
                                                    <option value="">Select Quantity name</option>
                                                    <option v-for="(item, index) in sensorUnits" :key="index" class="fa" :value="item.quantity_name">{{ item.quantity_name }}</option>
                                                </select>
                                                <!-- <input  type="text" class="form-control" v-model="newProfile.attributes[0].unit.quantity_name"> -->
                                                <span class="search-icon"><img src="../../../assets/img/icons/search-icon.svg" alt="Icon"></span>
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span>Rule :</span>
                                            <span><input v-model="newProfile.attributes[0].unit.unit" type="text" placeholder="m/s" class="form-control"></span>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingTwo">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <span>Icons</span>
                                    <span class="">
                                        <span class="icon-up">
                                            <i class="fa fa-angle-up"></i>
                                        </span>
                                        <span class="icon-down">
                                            <i class="fa fa-angle-down"></i>
                                        </span>
                                    </span>
                                </button>
                            </h5>
                        </div>
                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                            <div class="card-body">
                                <ul>
                                    <li>
                                        <p>
                                            <span>Category</span>
                                            <select v-model="newProfile.category" style="width: fit-content;" name="" class="fa form-control">
                                                <option class="fa" value="">&#xf7c0; Option</option>
                                                <option class="fa" value="sensor">&#xf7c0; Sensor</option>
                                                <option class="fa" value="option2">&#xf00c; Option2</option>
                                                <option class="fa" value="option2">&#xf30b; Option2</option>
                                            </select>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="action-btn text-right mt-5">
            <button type="button" @click="addNewProfile()" class="btn btn-save btn-dark mr-3">Save</button>
            <a href="#" @click="discardAllChanges()" class="btn btn-dark">Discard</a>
        </div>
    </div>
</DefaultLayout>
<div class="modal fade iot-modal edit-profile-name-modal" id="editProfileNameModal" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    Edit Profile Name
                </h5>
            </div>
            <div class="modal-body">
                <form>
                    <div class="form-group">
                        <label for="profileName" class="col-form-label"><b>Profile Name:</b></label>
                        <input v-model="newProfile.profile_name" type="text" class="form-control" id="profileName">
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-dark" data-dismiss="modal">Save</button>
                <button @click="discardChange()" type="button" class="btn btn-dark" data-dismiss="modal">Discard</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import DefaultLayout from '@/components/DefaultLayout.vue'
import Header from '@/components/Header.vue'
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import axios from 'axios'
export default {
    name: 'ProfileAddNew',
    data: function () {
        return {
            sensorUnits: [],
            newProfile: {
                profile_name: "Add new profile",
                msg_type: "json",
                category: "",
                attributes: [{
                    attribute_name: " ",
                    data_type: "float",
                    unit: {
                        quantity_name: "",
                        unit: "m/s/h"
                    }
                }]
            },
            profileBeforeEdit: {}
        }
    },
    components: {
        DefaultLayout,
        Header
    },
    created() {
        this.profileBeforeEdit = Object.assign({}, this.newProfile)
        this.profileNameBeforeEdit = this.newProfile.profile_name
    },
    methods: {
        setRule(event) {
            this.newProfile.attributes[0].unit.unit = event.target.value;
        },
        getSensorUnits() {
            axios.get("sensors/units").then((response) => {
                this.sensorUnits = response.data.data;
            });
        },
        discardAllChanges() {
            Object.assign(this.newProfile, this.profileBeforeEdit)
        },
        discardChange() {
            this.newProfile.profile_name = this.profileNameBeforeEdit
        },

        addNewProfile() {
            console.log(this.newProfile)
            axios.post("sensors/profiles", this.newProfile).then((response) => {
                if (response.data.status == 'SUCCESS') {
                    createToast('Device is added successfully', {
                        position: 'bottom-right',
                        showIcon: 'true',
                        type: 'success',
                        transition: 'zoom',
                    });
                }
            });
        }
    },
    mounted() {

        this.getSensorUnits();

    }
}
</script>
