<template>
    <div>
        <canvas id="chartModel" height="400"></canvas>
    </div>
</template>

<script>
    import Chart from 'chart.js/auto';
    export default {
        name: 'DevicesChart',
        data: function () {
            return {
                chart: null,
                ctx: null,
            }
        },
        props: {
            chartType: {
                type: String,
                default: '',
            },
            chData: {
                type: [Array, Object],
                default: {},
            },
        },
        components: {},
        methods: {
            updateChart(){
                this.chart.destroy();
                this.chart = new Chart(this.ctx, {
                    type: 'bubble',
                    data: this.chData,
                    options: {

                        maintainAspectRatio: false,
                        scales: {
                            y: {
                                max: 15,
                                min: 0,
                                ticks: {
                                    stepSize: 3
                                }
                            },
                            x: {
                                max: 20,
                                min: 0,
                                ticks: {
                                    stepSize: 3
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: false
                            },
                        }

                    }
                });
                this.chart;

            }
        },
        beforeDestroy () {
//            this.chart.destroy();
        },
        mounted() {
            this.ctx = document.getElementById('chartModel');
            this.chart = new Chart(this.ctx, {
                type: 'bubble',
                data: this.chData,
                options: {
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            max: 15,
                            min: 0,
                            ticks: {
                                stepSize: 3
                            }
                        },
                        x: {
                            max: 20,
                            min: 0,
                            ticks: {
                                stepSize: 5
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                    }


                }
            });
            this.chart;
        },
        beforeUpdate(){},
        updated() {},
        watch: {
            chData(newVal, oldVal) {
                if (oldVal.datasets[0].label != newVal.datasets[0].label) {
                    this.updateChart();
                }

            }
        },
    }
</script>


