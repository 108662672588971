<template>
  <DefaultLayout>
    <Header
        back-link="/devices-detail"
        header-title="Device Detail"
        header-breadcrumb="Sensor / Devices / Devices Detail"
    />
    <div class="main-content device-detail">
      <div class="row justify-content-center">
        <div v-if="loaderActive" class="main-card">
          <loader :active="loaderActive" />
        </div>
        <div v-else class="col-md-10">
          <div class="device-detail-config-title-wrapper">
            <h3 class="title">Water Level A</h3>
            <router-link class="btn btn-dark" :to="'/devices-detail-edit/'+sensorID"
              >Edit</router-link
            >
          </div>
          <div class="card device-detail-card">
            <ul>
              <li>
                <p><b>Sensor ID :</b></p>
                <p>{{ deviceDetails.sensor_id }}</p>
              </li>
              <li>
                <p><b>Display Name :</b></p>
                <p>{{ deviceDetails.display_name }}</p>
              </li>
              <li>
                <p><b>Sensor Profile :</b></p>
                <p>{{ deviceDetails.profile_name }}</p>
              </li>
              <li>
                <p><b>Type :</b></p>
                <p>{{ deviceDetails.sensor_type }}</p>
              </li>
            </ul>
            <ul>
              <li>
                <p><b>Group name :</b></p>
                <p>{{ deviceDetailConfig.groupName }}</p>
              </li>
              <li>
                <p><b>Description :</b></p>
                <p>{{ deviceDetailConfig.description }}</p>
              </li>
            </ul>
          </div>
          <div id="accordion" class="iot-accordion profile-accordion mt-5">
            <div class="card">
              <div class="card-header" id="headingOne">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    <span> Calibration</span>
                    <span class="">
                      <span class="icon-up">
                        <i class="fa fa-angle-up"></i>
                      </span>
                      <span class="icon-down">
                        <i class="fa fa-angle-down"></i>
                      </span>
                    </span>
                  </button>
                </h5>
              </div>
              <div
                id="collapseOne"
                class="collapse"
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div class="card-body">
                  <ul v-if="calibrations.length">
                    <li v-for="(item, index) in calibrations" :key="index" >
                      <p>
                        <span>Attribute Name: </span>
                        <span>{{ item.attribute_name }}</span>
                      </p>
                      <p>
                        <span>Rule : </span>
                        <span>{{ item.rule }}</span>
                      </p>
                    </li>
                  </ul>
                  <p v-else>No Calibrations found</p>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingTwo">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <span> Custom Attributes </span>
                    <span class="">
                      <span class="icon-up">
                        <i class="fa fa-angle-up"></i>
                      </span>
                      <span class="icon-down">
                        <i class="fa fa-angle-down"></i>
                      </span>
                    </span>
                  </button>
                </h5>
              </div>
              <div
                id="collapseTwo"
                class="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordion"
              >
                <div class="card-body">
                  <ul>
                    <li>
                      <p>
                        <span>Attribute Name: </span>
                        <span>{{
                          deviceDetailConfig.customAttributeName
                        }}</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span>Rule : </span>
                        <span>{{ deviceDetailConfig.customRule }}</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span>Unit : </span>
                        <span>{{ deviceDetailConfig.customUnit }}</span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingThree">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <span> Health Setting </span>
                    <span class="">
                      <span class="icon-up">
                        <i class="fa fa-angle-up"></i>
                      </span>
                      <span class="icon-down">
                        <i class="fa fa-angle-down"></i>
                      </span>
                    </span>
                  </button>
                </h5>
              </div>
              <div
                id="collapseThree"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordion"
              >
                <div class="card-body health-settings">
                  <div class="custom-control custom-switch">
                    <input
                      v-model="deviceDetailConfig.healthSettings"
                      type="checkbox"
                      class="custom-control-input"
                      id="customSwitch1"
                    />
                    <label class="custom-control-label" for="customSwitch1"
                      >Toggle this switch element</label
                    >
                  </div>
                  <div class="ml-2 mt-4 d-flex align-items-center">
                    <p>
                      <small
                        ><b
                          >Expected Maximum data updating span in seconds</b
                        ></small
                      >
                    </p>
                    :
                    <p class="ml-3">
                      <small>{{
                        deviceDetailConfig.expectedMaximumData
                      }}</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/components/DefaultLayout.vue";
import Header from "@/components/Header.vue";
import Loader from "@/components/Loader.vue";
export default {
  name: "DevicesDetailConfig",
  data: function () {
    return {
      loaderActive: true,
      sensorID:'',
      deviceDetails:[],
      calibrations:[],
      deviceDetailConfig: {
        sensorID: "sigfox-4F45F3B3",
        displayName: "Sigfox tilt sensor A",
        sensorProfile: "SPEC_CODE_SIGFOX_TILT",
        type: "TILT",
        groupName: "Project 425",
        description: "DC/2019/08 Tsui Ping River Revitalization",
        calAttributeName: "Level",
        calRule: "water_level - ground_level + 82",
        customAttributeName: "Level",
        customRule: "water_level - ground_level + 82",
        customUnit: "34",
        healthSettings: true,
        expectedMaximumData: "6",
      },
    };
  },
  components: {
    DefaultLayout,
    Header,
    Loader,
  },
  methods: {
    getSensorData() {
      axios.get("sensors/" + this.sensorID).then((response) => {
        this.deviceDetails = response.data.data;
        this.loaderActive = false;
      });
    },
    getSensorCalibrations()
    {
        axios.get("sensors/"+this.sensorID+"/calibrations").then((response) => {
        this.calibrations = response.data.data;
        console.log("Calibrations = ",this.calibrations);
      });
    }
  },
  mounted() {
    this.sensorID = this.$route.params.id;
    this.getSensorData();
    this.getSensorCalibrations();
  },
};
</script>