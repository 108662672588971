<template>
<DefaultLayout>
    <Header back-link="/devices-detail-config" header-title="Device Detail Edit" header-breadcrumb="Sensor / Devices / Devices Detail Edit" />
    <div class="main-content device-add">
        <div class="row justify-content-center">
            <div v-if="loaderActive" class="main-card">
                <loader :active="loaderActive" />
              </div>
            <div v-else class="col-md-10">

                <div class="device-detail-config-title-wrapper">
                    <h3 class="title">
                        Water Level A
                        <!--<span class="ml-3"><i class="fa fa-pencil-alt"></i></span>-->
                    </h3>
                </div>
                <div class="card device-detail-card">
                    <ul>
                        <li>
                            <p><b>Sensor ID :</b></p>
                            <p><input v-model="deviceDetail.sensor_id" type="text" class="form-control"></p>
                        </li>
                        <li>
                            <p><b>Display Name :</b></p>
                            <p><input v-model="deviceDetail.display_name" type="text" class="form-control"></p>
                        </li>
                        <li>
                            <p><b>Sensor Profile :</b></p>
                            <p class="position-relative">
                                <input v-model="deviceDetail.profile_name" type="text" placeholder="Type" class="form-control">
                                <span class="search-icon"><img src="../../../assets/img/icons/search-icon.svg" alt="Icon"></span>
                            </p>
                        </li>
                        <li>
                            <p><b>Type :</b></p>
                            <p>
                                <select v-model="deviceDetail.sensor_type" class="form-control" name="" id="">
                                    <Option value="TILT">TILT</Option>
                                    <Option value="gps">GPS</Option>
                                    <Option value="air-quality">Air Quality</Option>
                                    <Option value="smart-helmet">Smart Helmet</Option>
                                </select>
                            </p>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <p><b>Group name :</b></p>
                            <p><input v-model="deviceEdit.groupName" type="text" class="form-control"></p>
                        </li>
                        <li>
                            <p><b>Description :</b></p>
                            <p><input v-model="deviceEdit.description" type="text" class="form-control"></p>
                        </li>
                    </ul>
                </div>
                <div id="accordion" class="iot-accordion profile-accordion mt-5">
                    <div class="card">
                        <div class="card-header" id="headingOne">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    <span>
                                        Calibration
                                    </span>
                                    <span class="">
                                        <span class="icon-up">
                                            <i class="fa fa-angle-up"></i>
                                        </span>
                                        <span class="icon-down">
                                            <i class="fa fa-angle-down"></i>
                                        </span>
                                    </span>
                                </button>
                            </h5>
                        </div>
                        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                            <div class="card-body">
                                <ul v-if="calibrations.length">
                                    <li v-for="(item,index) in calibrations" :key="index">
                                        <p>
                                            <span>Attribute Name: </span>
                                            <span><input v-model="item.attribute_name" type="text" class="form-control"></span>
                                        </p>
                                        <p>
                                            <span>Rule : </span>
                                            <span>
                                                <input v-model="item.rule" type="text" class="form-control">
                                            </span>
                                        </p>
                                    </li>
                                </ul>
                                <p v-else>No Calibrations found.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingTwo">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <span>
                                        Custom Attributes
                                    </span>
                                    <span class="">
                                        <span class="icon-up">
                                            <i class="fa fa-angle-up"></i>
                                        </span>
                                        <span class="icon-down">
                                            <i class="fa fa-angle-down"></i>
                                        </span>
                                    </span>
                                </button>
                            </h5>
                        </div>
                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                            <div class="card-body">
                                <ul>
                                    <li>
                                        <p>
                                            <span>Attribute Name: </span>
                                            <span><input v-model="NewCalibration.attribute_name" type="text" class="form-control"></span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span>Rule : </span>
                                            <span><input v-model="NewCalibration.rule" type="text" class="form-control"></span>
                                        </p>
                                    </li>
                                </ul>
                                <button type="button" @click="addCalibration(NewCalibration)" class="btn btn-dark"><i class="fa fa-plus"></i> Add Attributes</button>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingThree">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <span>
                                        Health Setting
                                    </span>
                                    <span class="">
                                        <span class="icon-up">
                                            <i class="fa fa-angle-up"></i>
                                        </span>
                                        <span class="icon-down">
                                            <i class="fa fa-angle-down"></i>
                                        </span>
                                    </span>
                                </button>
                            </h5>
                        </div>
                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                            <div class="card-body health-settings">
                                <div class="custom-control custom-switch">
                                    <input v-model="deviceEdit.healthSettings" type="checkbox" class="custom-control-input" id="customSwitch1">
                                    <label class="custom-control-label" for="customSwitch1">Toggle this switch element</label>
                                </div>
                                <div class="ml-2 mt-4 d-flex align-items-center">
                                    <p>
                                        <small><b>Expected Maximum data updating span in seconds</b></small> :
                                    </p>
                                    <p class="ml-3">
                                        <input v-model="deviceEdit.expectedMaximumData" type="text" class="form-control">
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="action-btn text-right mt-5">
            <button type="button" @click="updateSensor()" class="btn btn-dark mr-3">Save</button>
            <a href="/devices" class="btn btn-dark">Discard</a>
        </div>
    </div>
</DefaultLayout>
</template>

<script>
import DefaultLayout from '@/components/DefaultLayout.vue'
import Header from '@/components/Header.vue'
import Loader from '@/components/Loader.vue'
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
export default {
    name: 'DevicesDetailEdit',
    data: function () {
        return {
            loaderActive: true,
            sensorID: '',
            calibrations:[],
            NewCalibration:{
                attribute_name:'',
                rule:''
            },
            deviceDetail: {
                sensor_id: '',
                display_name: '',
                device_name: '',
                profile_name: '',
                sensor_type: '',
            },
            deviceEdit: {
                groupName: 'Project 425',
                description: 'DC/2019/08 Tsui Ping River Revitalization',
                calAttributeName: '',
                calRule: '',
                customAttributeName: '',
                customRule: '',
                customUnit: '',
                healthSettings: false,
                expectedMaximumData: '',
            },
        }
    },
    components: {
        DefaultLayout,
        Header,
        Loader,
    },
    methods: {
        getSensorDetail() {
            axios.get("sensors/" + this.sensorID).then((response) => {
                this.deviceDetail.sensor_id = response.data.data.sensor_id;
                this.deviceDetail.device_name = response.data.data.device_name;
                this.deviceDetail.profile_name = response.data.data.profile_name;
                this.deviceDetail.display_name = response.data.data.display_name;
                this.deviceDetail.sensor_type = response.data.data.sensor_type;
                this.loaderActive = false;

            });
        },
        updateSensor() {
            axios.patch("sensors/" + this.sensorID, this.deviceDetail).then((response) => {

                if (response.data.status == 'SUCCESS') {
                    createToast('Devices detail updated successfully', {
                        position: 'bottom-right',
                        showIcon: 'true',
                        type: 'success',
                        transition: 'zoom',
                    });
                }
            });
        },
        getSensorCalibrations() {
            axios.get("sensors/" + this.sensorID + "/calibrations").then((response) => {
                this.calibrations = response.data.data;
                console.log("Calibrations", this.calibrations);
            });
        },
        addCalibration(NewCalibration)
        {
            axios.post("sensors/"+this.sensorID+"/calibrations",NewCalibration).then((response) => {
                if(response.data.status=="SUCCESS")
                {
                    createToast('Calibration attribute is added successfully', {
                        position: 'bottom-right',
                        showIcon: 'true',
                        type: 'success',
                        transition: 'zoom',
                    });
                    this.getSensorCalibrations();
                }
            });
        }
    },
    mounted() {
        this.sensorID = this.$route.params.id;
        this.getSensorDetail();
        this.getSensorCalibrations();
    }
}
</script>
