import {
  createRouter,
  createWebHistory
} from 'vue-router'

//Components Routes


import HomeView from '../views/Sensor/Profiles/HomeView.vue'
import ProfileAddNew from '../views/Sensor/Profiles/ProfileAddNew.vue'
import ProfileDetail from '../views/Sensor/Profiles/ProfileDetail.vue'
import ProfileDetailEdit from '../views/Sensor/Profiles/ProfileDetailEdit.vue'
import UserGroup from '../views/User/UserGroup/UserGroup.vue'
import UserGroupDetail from '../views/User/UserGroup/UserGroupDetail.vue'
import UserGroupDetailEdit from '../views/User/UserGroup/UserGroupDetailEdit.vue'
import UserGroupAddNew from '../views/User/UserGroup/UserGroupAddNew.vue'
import Devices from '../views/Sensor/Devices/Devices.vue'
import DevicesAddNew from '../views/Sensor/Devices/DevicesAddNew.vue'
import DevicesDetail from '../views/Sensor/Devices/DevicesDetail.vue'
import DevicesDetailConfig from '../views/Sensor/Devices/DevicesDetailConfig.vue'
import DevicesDetailEdit from '../views/Sensor/Devices/DevicesDetailEdit.vue'
import DevicesDetailTableView from '../views/Sensor/Devices/DevicesDetailTableView.vue'
import DevicesDetailReadings from '../views/Sensor/Devices/DevicesDetailReadings.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/profile-add-new',
        name: 'ProfileAddNew',
        component: ProfileAddNew
    },
    {
        path: '/profile-detail/:id',
        name: 'ProfileDetail',
        component: ProfileDetail
    },
    {
        path: '/profile-detail-edit/:id',
        name: 'ProfileDetailEdit',
        component: ProfileDetailEdit
    },
    {
        path: '/user-group',
        name: 'UserGroup',
        component: UserGroup
    },
    {
        path: '/user-group-detail/:id',
        name: 'UserGroupDetail',
        component: UserGroupDetail
    },
    {
        path: '/user-group-detail-edit/:id',
        name: 'UserGroupDetailEdit',
        component: UserGroupDetailEdit
    },
    {
        path: '/user-group-add-new',
        name: 'UserGroupAddNew',
        component: UserGroupAddNew
    },
    {
        path: '/devices',
        name: 'Devices',
        component: Devices
    },
    {
        path: '/devices-add-new',
        name: 'DevicesAddNew',
        component: DevicesAddNew
    },
    {
        path: '/devices-detail/:id',
        name: 'DeviceDetail',
        component: DevicesDetail
    },
    {
        path: '/devices-detail-config/:id',
        name: 'DeviceDetailConfig',
        component: DevicesDetailConfig
    },
    {
        path: '/devices-detail-edit/:id',
        name: 'DeviceDetailEdit',
        component: DevicesDetailEdit
    },
    {
        path: '/devices-detail-table-view/:id',
        name: 'DevicesDetailTableView',
        component: DevicesDetailTableView
    },
    {
        path: '/device-detail-readings',
        name: 'DevicesDetailReadings',
        component: DevicesDetailReadings
    }
  // {
  //     path: '/about',
  //     name: 'about',
  //     // route level code-splitting
  //     // this generates a separate chunk (about.[hash].js) for this route
  //     // which is lazy-loaded when the route is visited.
  //     component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]


const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router