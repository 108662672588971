<template>
    <DefaultLayout>
        <Header back-link="/devices-detail-table-view" header-title="Detail Readings"
                header-breadcrumb="Sensor / Devices / Device Detail / Detail Readings"/>
        <div class="main-content device-detail-readings">
            <div class="row justify-content-center">
                <div class="col-md-10">
                    <div class="reading-summary">
                        <div class="search-by-date">
                            <span class="search-icon mr-3"><img src="../../../assets/img/icons/search-icon.svg" alt="Icon"></span>
                            <Datepicker class="mr-3" v-model="startDate"></Datepicker>
                            <span class="mr-3">TO</span>
                            <Datepicker v-model="endDate"></Datepicker>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="iot-table table table-hover mt-2" id="profileTable">
                            <thead>
                            <tr>
                                <th>Section <i class="fa fa-sort"></i></th>
                                <th>Strength</th>
                                <th>Value</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>5</td>
                                <td>12.356</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>10</td>
                                <td>24.678</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>15</td>
                                <td>23.13</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>20</td>
                                <td>45.823</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>25</td>
                                <td>23.854</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>30</td>
                                <td>67.345</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>35</td>
                                <td>34.67</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>40</td>
                                <td>72.453</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </DefaultLayout>
</template>

<script>
    import DefaultLayout from '@/components/DefaultLayout.vue'
    import Header from '@/components/Header.vue'
    import Datepicker from '@vuepic/vue-datepicker'
    import '@vuepic/vue-datepicker/dist/main.css'


    export default {
        name: 'DevicesDetailReadings',
        data: function () {
            return {
                startDate: null,
                endDate: null,
            }
        },
        props: {},
        components: {
            DefaultLayout,
            Header,
            Datepicker
        },
        computed: {},
        methods: {
        },
        mounted() {
        },
        watch: {},
    }
</script>

<style>

</style>